<template>
    <div class="scan-ticket">
        <h2 class="scan-ticket__title">{{ getTranslatedText('scan ticket') }}</h2>
        <div class="scan-ticket__select-sport">
            <RadioCommon value="L" label="Lotto" :valueChecked="ticketType" nameFunction="on-select-ticket-type" paramFunction="L" />
            <RadioCommon value="S" label="Sport" :valueChecked="ticketType" nameFunction="on-select-ticket-type" paramFunction="S" />
        </div>
        <div class="scan-ticket__input" v-if="!isShowScan">
            <label>{{ getTranslatedText('ticket code') }}</label>
            <div class="scan-ticket__input__content">
                <span class="scan-ticket__input-left-addon">{{ ticketType }} -</span>
                <input v-model="ticketNumber" type="text" />
            </div>
        </div>
        <div class="scan-ticket__box-button">
            <ButtonCommon v-if="!isShowScan" className="w100" :isNoHover="true" :isLoading="isLoadingButton" :isDisable="ticketNumber === ''" value="search" nameFunction="on-search-ticket-detail" />
            <span v-if="!isShowScan" class="scan-ticket__text-or">{{ getTranslatedText('or') }}</span>
            <ButtonCommon className="w100" :isNoHover="true" :isLoading="isLoadingButton" type="active" :value="isShowScan ? 'close ticket' : 'scan ticket'" nameFunction="on-scan-code-bar" />
            <template v-if="!$store.state.isOnlyPayment">
                <span v-if="!isShowScan" class="scan-ticket__text-or">{{ getTranslatedText('or') }}</span>
                <ButtonCommon v-if="!isShowScan" className="w100" :isNoHover="true" :isLoading="isLoadingButton" :isDisable="ticketNumber === ''" value="Replay" @on-click-button-common="onCheckGameRebet" />
            </template>
        </div>
    </div>
</template>
<script>
import EventBus from '@/utils/EventBus'
import languageMixins from '@/mixins/language/language-mixins'
import printMixins from '@/mixins/print/print-mixin'
import RadioCommon from '@/components/common/radio/index.vue'
import ButtonCommon from '@/components/common/button/index.vue'
import BetslipsService from '@/services/betslip.service'

import { getDrawName, isObjEmpty, formatDate, isArrayEmpty, generateCombinations } from '@/utils/utils'

export default {
    mixins: [languageMixins, printMixins],
    components: { RadioCommon, ButtonCommon },
    data() {
        return {
            ticketType: 'L',
            ticketNumber: '',
            isShowTrue: false,
            isShowScan: false,
            idTimeOut: null,
            isLoadingButton: false,
        }
    },
    mounted() {
        document.addEventListener('message', this.onDecode)
    },
    created() {
        EventBus.$on('on-select-ticket-type', (type) => {
            this.ticketType = type
            this.ticketNumber = ''
        })
        EventBus.$on('on-search-ticket-detail', () => this.onSearchTicketDetail())
        EventBus.$on('on-scan-code-bar', () => this.onisShowScan())
        EventBus.$on('on-hide-camere-scan', () => (this.isShowScan = false))
    },
    destroyed() {
        EventBus.$off('on-select-ticket-type')
        EventBus.$off('on-search-ticket-detail')
        EventBus.$off('on-scan-code-bar')
        EventBus.$off('on-hide-camere-scan')
    },
    methods: {
        showNotification(text, type) {
            const param = {
                text: text,
                type: type,
            }
            EventBus.$emit('show-notications', param)
        },

        onisShowScan() {
            this.isShowScan = !this.isShowScan
            parent.postMessage({ command: 'is-show-cammera', param: this.isShowScan }, '*')
        },

        onCheckGameRebet() {
            this.ticketType === 'L' ? this.onRebetTicket() : this.onRebetSports()
        },

        async onSearchTicketDetail() {
            try {
                const param = await this.validateGetTicket()
                if (!isObjEmpty(param.response)) {
                    const { response, ticket } = param

                    if (response.status !== 200) {
                        this.showNotification(response.message, 'error')
                        this.isLoadingButton = false
                    } else {
                        const payload = {
                            data: response.data,
                            ticketId: ticket,
                            ticketType: this.ticketType,
                        }

                        EventBus.$emit('on-show-ticket-detail', payload)
                        this.isLoadingButton = false
                    }
                }
            } catch (error) {
                console.log(error)
                this.isLoadingButton = false
            }
        },

        async onRebetTicket() {
            if (this.isLoadingButton === true) return;
            try {
                this.isLoadingButton = true
                const respCheckRebet = await BetslipsService.checkRebetLoto(this.ticketNumber)

                if (respCheckRebet.status === 200 && respCheckRebet.data.game_slug === 'lotto-6-45' && !this.$store.state.isShow645) {
                    this.showNotification(`we're not allow rebet for 6/45`, 'error')
                    this.isLoadingButton = false
                    return false
                }

                if (respCheckRebet.status === 200 && respCheckRebet.data.game_slug === 'lotto-4-59' && !this.$store.state.isShow459) {
                    this.showNotification(`we're not allow rebet for 4/59`, 'error')
                    this.isLoadingButton = false
                    return false
                }

                if (respCheckRebet.status === 200 && respCheckRebet.data.game_slug === 'lotto-5-90' && !this.$store.state.isShow590) {
                    this.showNotification(`we're not allow rebet for 5/90`, 'error')
                    this.isLoadingButton = false
                    return false
                }

                if (respCheckRebet.status !== 200) {
                    this.showNotification(respCheckRebet.message, 'error')
                    this.isLoadingButton = false
                    return false
                }

                const param = await this.validateGetTicket()
                if (!isObjEmpty(param.response)) {
                    const { response } = param

                    if (response.status !== 200) {
                        this.showNotification(response.message, 'error')
                        this.isLoadingButton = false
                    } else {
                        let respRebet = []
                        let game_slug = respCheckRebet.data.game_slug

                        let isLoto645 = game_slug === 'lotto-6-45' || game_slug === 'loto-4-59'
                        if (isLoto645) {
                            respRebet = await BetslipsService.reBetTicket645(this.ticketNumber)
                        } else {
                            respRebet = await BetslipsService.reBetTickets(response.data.transaction.reference)
                        }
                        let payload = []
                        if (respRebet.code === 200 && !isArrayEmpty(respRebet.data.bets?.entries)) {
                            const bet_timestamp = new Date().getTime() / 1000

                            respRebet.data.bets.entries.map((x) => {
                                let item = {
                                    game: x.game.game,
                                    wager: x.entry.wager,
                                    draws: 1,
                                    event_id: [x.rebet.eventId],
                                    selection: x.entry.bet,
                                }
                                if (isLoto645) {
                                    let combinationsLength = game_slug === 'lotto-6-45' ? 6 : 4
                                    let combinations = generateCombinations(Number(x.entry.bet[0].length), combinationsLength)
                                    let wager = x.entry.wager / combinations

                                    item.wager = this.$store.dataGamesPari?.game?.price ? this.$store.dataGamesPari?.game?.price : wager
                                }
                                payload.push(item)
                            })
                            let resp = []
                            if (isLoto645) {
                                resp = await BetslipsService.transactLotto(payload, bet_timestamp, 'pari-mutuel-lotto', this.ticketNumber)
                            } else {
                                resp = await BetslipsService.transactLotto(payload, bet_timestamp, 'multi-lotto', this.ticketNumber)
                            }
                            if (resp.code === 200) {
                                let dataEntries = resp.data.bets.entries.map((x) => {
                                    let drawName = ''
                                    if (isLoto645) {
                                        drawName = this.getTranslatedText(x.game.game)
                                    } else {
                                        const dateFormat = formatDate(new Date(x.event.drawDate))
                                        let date = `${dateFormat.hour24h}`
                                        drawName = getDrawName(date)
                                    }

                                    let item = {
                                        ...x,
                                    }

                                    if (isLoto645) {
                                        let numberSelect = x.market.market.slice(-1)
                                        item.game['game'] += ` ${this.getTranslatedText('Milliardaire')}`

                                        if (x.entry.bet[0].length > numberSelect) {
                                            item['combinations'] = generateCombinations(Number(x.entry.bet[0].length), Number(numberSelect))
                                            item['type'] = `${this.getTranslatedText('multiplePrint')}${x.entry.bet[0].length}`
                                        } else {
                                            item['type'] = `${this.getTranslatedText('simple')}`
                                            item['combinations'] = 1
                                        }
                                    }

                                    // add show jackpot
                                    var drawNumber = (stepCart === 3) ? "459" : (stepCart === 4) ? "545" : null;
                                    if (drawNumber) {
                                        let firstDraw = JSON.parse(localStorage.getItem("draw-" + drawNumber));
                                        if (firstDraw.event_id == item.event.eventId) {
                                            item.game.jackpot = localStorage.getItem("jackpot-" + drawNumber);
                                        }
                                    }

                                    item.event['drawName'] = drawName
                                    return item
                                })

                                resp.data.bets['entries'] = dataEntries

                                resp.data['isCombinations'] = isLoto645

                                const param = resp.data

                                this.isLoadingButton = false
                                parent.postMessage({ command: 'print-rebet-ticket', param }, '*')
                                this.printLoto(param)
                                
                            } else {
                                this.showNotification(resp.message, 'error')
                                this.isLoadingButton = false
                            }
                        } else {
                            let message = respRebet.code === 200 ? respRebet.data.message : respRebet.message
                            this.showNotification(message, 'error')
                            this.isLoadingButton = false
                        }
                    }
                }
            } catch (error) {
                console.log(error)
            }
        },

        async onRebetSports() {
            if (this.isLoadingButton === true) return;

            this.isLoadingButton = true

            try {
                const param = await this.validateGetTicket()
                if (!isObjEmpty(param.response)) {
                    const { response } = param
                    if (response.status !== 200) {
                        this.showNotification(response.message, 'error')
                        this.isLoadingButton = false
                    } else {
                        const param = {
                            url: response.data.sport_url,
                            code: response.data.sport_code,
                            betId: response.data.sport_bet_id,
                            token: response.data.sport_token,
                        }
                        const respRebet = await BetslipsService.reBetSports(param)
                        if (respRebet.code === 200) {
                            const betStorageData = JSON.parse(respRebet.print_data)
                            const voucher = betStorageData[0].voucher
                            const transactions = betStorageData[0].transactions
                            const paramPrint = {
                                voucher,
                                transactions,
                            }
                            parent.postMessage({ command: 'print-rebet-sport', param: paramPrint }, '*')
                            this.printSports(JSON.stringify([paramPrint]))
                            this.isLoadingButton = false
                        } else {
                            this.showNotification(respRebet.message, 'error')
                            this.isLoadingButton = false
                        }
                    }
                }
            } catch (error) {
                this.isLoadingButton = false
                console.log(error)
            }
        },

        async validateGetTicket() {
            this.isLoadingButton = true
            let ticket = this.ticketNumber
            try {
                ticket = `${this.ticketType}-${ticket}`

                const response = await BetslipsService.searchBetslip(ticket)

                const param = {
                    response,
                    ticket,
                }
                return param
            } catch (error) {
                console.log(error)
            }
        },

        onDecode(event) {
            const resp = JSON.parse(event.data)
            if (resp.command === 'on-decode-scan') {
                let value = resp.data
                if (value.startsWith('L-')) {
                    this.ticketNumber = value.replace('L-', '')
                    this.ticketType = 'L'
                    this.isShowScan = false
                    parent.postMessage({ command: 'is-show-cammera', param: this.isShowScan }, '*')
                } else if (value.startsWith('S-')) {
                    this.ticketNumber = value.replace('S-', '')
                    this.ticketType = 'S'
                    this.isShowScan = false
                    parent.postMessage({ command: 'is-show-cammera', param: this.isShowScan }, '*')
                }
            }
        },
    },
}
</script>

<style lang="scss">
@import '~ThemePath/scss/scan-ticket/_index.scss';
</style>
